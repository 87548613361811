import { Controller } from "@hotwired/stimulus"
import { DataSet, Timeline } from "vis-timeline/standalone";


// Connects to data-controller="gant-charts"
export default class extends Controller {

  static targets = ["item"]


  connect() {
    const container = this.element;
    let spans = this.itemTargets;
    let groups = new DataSet();
    let items = new DataSet();

    const extensionTheme = "background-color: rgb(12 34 108);";
    const baseTheme = "background-color: #38a390;";
    const riskTheme = "background-color: transparent;" + "border-color: red;";   

  
    const randomInt = () => {
      return Math.floor(Math.random() * 999999)
    };
    
    spans.forEach(item => {
      let name = item.dataset.gantChartsName
      let group = item.dataset.gantChartsGroup
      let start = item.dataset.gantChartsStart
      let end = item.dataset.gantChartsEnd
      let status = item.dataset.gantChartsStatus
      let riskId = item.dataset.gantChartsRiskId
      
      groups.update({
        id: name,
        content:name,
      });

      if(status == "base"){
        items.add({
          id: randomInt(),
          group: group, 
          start: start, 
          end: end,
          type: 'range',
          style: baseTheme,
          title: name,
          className: 'base-item'
        })
      } else if(status == "extension"){
        items.add({
          id: randomInt(),
          group: group, 
          start: start, 
          end: end,
          type: 'range',
          style: extensionTheme,
          title: name,
          className: 'extension-item'
        })
      } else if (status == "risk") {
        const content = riskId
          ? `<a href="/projects/${this.element.dataset.gantChartsProjectId}/risks/${riskId}" class="risk-link" data-turbo="false" turbo-visit-control="reload">&nbsp;</a>`
          : name;
        
        items.add({
          id: randomInt(),
          group: group, 
          start: start, 
          end: end,
          type: 'range',
          style: riskTheme,
          title: name,
          className: 'risk-item',
          content: content,
        })
      } else {
        console.log("no status")
      }

    });

    // Configuration for the Timeline
    const options = {
      hiddenDates: [
        {
          start: "2025-01-11 00:00:00",
          end: "2025-01-13 00:00:00",
          repeat: "weekly"
        }
      ],
      stack: false,
      showMinorLabels: true,
      groupHeightMode: 'fixed',
      horizontalScroll: true,
      verticalScroll: false,
      zoomKey: "ctrlKey",
      dataAttributes: 'all',
      orientation: {
        axis: "both",
        item: "top"
      },
    };

    // Create a Timeline
    let timeline = new Timeline(container, false, options);

    timeline.setGroups(groups);
    timeline.setItems(items);

  }
}
